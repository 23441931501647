const GalleryData = [
    {
        id: 1,
        cover: require("../../Assets/Gallery/gallery1.jpg")
    },

    {
        id: 2,
        cover: require("../../Assets/Gallery/gallery2.jpg")
    },
    {
        id: 3,
        cover: require("../../Assets/Gallery/gallery3.jpg")
    },
    {
        id: 4,
        cover: require("../../Assets/Gallery/gallery4.jpg")
    },
    {
        id: 5,
        cover: require("../../Assets/Gallery/gallery5.jpg")
    },
    {
        id: 6,
        cover: require("../../Assets/Gallery/gallery6.jpg")
    },
    {
        id: 7,
        cover: require("../../Assets/Gallery/gallery7.jpg")
    },
]
export default GalleryData;